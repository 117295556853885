import React, { useState, useCallback, Children, isValidElement } from 'react';

import { Link as TSRLink } from '@tanstack/react-router';
import cn from 'classnames';
import { Link as RRLink } from 'react-router-dom';

import { useBreakpoint } from '@hooks';

import { Dropdown } from '../Dropdown';
import Icon from '../Icon';
import { Pill } from '../Pill';

import Tab from './Tab';

export interface TabsBaseProps {
  children: React.ReactNode;
  size: 'sm' | 'md';
  vertical?: boolean;
  type?: any;
  className?: string;
  defaultTab?: string;
  linked?: 'tsr' | 'rr';
  route?: string;
  splitTab?: boolean;
}

const mapTabsSize = {
  sm: 'text-sm',
  md: 'text-base',
};

const TabElement = ({ label, linked, path, className, onClick, children }) => {
  if (linked === 'tsr') {
    return (
      <TSRLink
        aria-labelledby={label}
        search={{ tab: path }}
        onClick={onClick}
        className={className}
      >
        {children}
      </TSRLink>
    );
  } else if (linked === 'rr') {
    return (
      <RRLink
        aria-labelledby={label}
        to={`?tab=${path}`}
        onClick={onClick}
        className={className}
      >
        {children}
      </RRLink>
    );
  } else {
    return (
      <>
        <button aria-labelledby={label} onClick={onClick} className={className}>
          {children}
        </button>
      </>
    );
  }
};

const TabsBase = ({
  children,
  size,
  vertical,
  type,
  className,
  defaultTab,
  route,
  linked,
  splitTab,
}: TabsBaseProps): JSX.Element => {
  const validChildren = Children.toArray(children).filter(
    (child, index) => isValidElement(child) && child.type === Tab,
  ) as any[];

  const breakpoint = useBreakpoint();

  const tabs = validChildren.map((child) => {
    return child.props;
  });

  const validActiveTab = tabs[0].label;

  const queryTabExist = validChildren.find(
    (child) => child.props.label === defaultTab,
  );

  const initialTab = queryTabExist ? queryTabExist.props.label : validActiveTab;

  const [activeTab, setActiveTab] = useState(initialTab);
  const handleActiveTab = useCallback((label, trackClick) => {
    setActiveTab(label);
    if (trackClick) {
      trackClick();
    }
  }, []);

  let tabPanel;

  if (breakpoint === 'sm') {
    tabPanel = (
      <Dropdown
        onChangeFunction={(value) => {
          handleActiveTab(
            value,
            tabs.find((tab) => tab.label === value).trackClick,
          );
        }}
        onChangeParameters={[]}
        value={activeTab}
      >
        {tabs.map((tab) => {
          return (
            <option key={tab.label} value={tab.label}>
              {tab.tabName}
            </option>
          );
        })}
      </Dropdown>
    );
  } else {
    tabPanel = (
      <div
        role="tablist"
        className={cn({
          'border-sp-neutral-300 mb-4 border-b':
            vertical === false && type === 'underline',
          'bg-sp-neutral-50 border-sp-neutral-100 mr-4 rounded-md p-1':
            type === 'buttonBorder',
          'mr-4': vertical === true && type === 'button',
          'my-4 ml-6 mr-6':
            vertical !== true && type === 'button' && splitTab === false,
          'my-4 ml-6 mr-0': splitTab === true && type === 'button',
        })}
      >
        {tabs.map((tab) => {
          let badge;
          if (tab.badge !== null) {
            if (typeof tab.badge === 'number') {
              badge = (
                <span className="ml-2">
                  <Pill
                    colour={
                      tab.label === activeTab && type === 'underline'
                        ? 'purple'
                        : 'gray'
                    }
                    text={tab.badge.toString()}
                  />
                </span>
              );
            } else {
              badge = <span className="ml-2">{tab.badge}</span>;
            }
          } else {
            badge = null;
          }
          return (
            <TabElement
              label={tab.label}
              linked={linked}
              path={linked ? tab.label : null}
              onClick={() => handleActiveTab(tab.label, tab.trackClick)}
              className={cn(
                mapTabsSize[size],
                'mr-6',
                {
                  'text-sp-primary bg-sp-primary-50':
                    tab.label === activeTab && type === 'button',
                  'text-sp-primary border-sp-primary border-b-2':
                    vertical === false &&
                    tab.label === activeTab &&
                    type === 'underline',
                  'text-sp-neutral bg-sp-white shadow':
                    tab.label === activeTab && type === 'buttonBorder',
                  'inline-block': linked,
                },
                { 'text-sp-neutral-500 mr-6': tab.label !== activeTab },
                {
                  'last:float-right': splitTab === true && type === 'button',
                },
                `mr-6 font-medium`,
                className,
              )}
              key={tab.label}
            >
              <span className={cn(`flex-col`)}>
                <span
                  className={cn(`flex flex-row items-center justify-center`)}
                >
                  {tab.iconName !== undefined && (
                    <Icon
                      className="mr-2"
                      size="xl"
                      name={tab.iconName}
                      color={'text-sp-primary'}
                    />
                  )}
                  <span>{tab.tabName}</span>

                  {badge}
                </span>
              </span>
            </TabElement>
          );
        })}
      </div>
    );
  }

  const tabContent = validChildren.find((child, index) => {
    return child.props.label === activeTab;
  });

  return (
    <div
      className={cn({
        'flex flex-row': vertical === true && breakpoint !== 'sm',
      })}
    >
      {tabPanel}
      {vertical === false && type === 'button' && (
        <div className="border-sp-neutral-200 border border-b"></div>
      )}

      <div className="w-full">{tabContent}</div>
    </div>
  );
};

TabsBase.defaultProps = {
  size: 'sm',
  vertical: false,
  type: null,
};

export default TabsBase;
